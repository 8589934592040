<template>
    <div class="navbar-top-bar">
        <div class="container">
            <div class="navbar-top-bar-row">
                <div class="navbar-top-bar-logo">
                    <a :href="location" class="site-brand" title="CYRRUS, a.s." target="_self">
                        <img src="/img/logo-cyrrus.svg" width="215" height="35" alt="CYRRUS, a.s." class="site-brand-logo"/>
                    </a>
                </div>

                <div class="navbar-top-bar-addons">
                    <div class="d-flex justify-content-end align-items-center align-content-center">
                        <div class="mr-3">
                            <span class="iconify text-cyrrus-orange" data-icon="mdi:phone"></span>
                            <a class="text-black text-decoration-none" href="tel:+420800297787" target="_self">
                                +420 <strong>800 297 787</strong>
                            </a>
                        </div>
                        <div class="lang-switcher" v-if="$settings.ENABLE_LANG_SWITCHER">
                            <b-dropdown
                                right
                                variant="link"
                                toggle-class="p-0 text-decoration-none"
                                no-caret
                                class="lang-switcher-link"
                            >
                                <template #button-content>
                                    <img :src="`/img/${activeLang.key}.svg`" :alt="activeLang.name" class="img border border-light rounded-sm img-fluid d-inline-block" />
                                </template>
                                <b-dropdown-item-button
                                    v-for="lang in langs.filter(item => item.key !== activeLang.key)"
                                    :key="lang.key"
                                    @click.prevent="onSetLang(lang)"
                                >
                                    <img :src="`/img/${lang.key}.svg`" :alt="lang.name" class="img border border-light rounded-sm mr-2 img-fluid d-inline-block" /> {{lang.name}}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    computed: {
        langs () {
            return [
                {
                    key: 'cz',
                    name: this.$t('lang.cz')
                },
                {
                    key: 'sk',
                    name: this.$t('lang.sk')
                },
                {
                    key: 'en',
                    name: this.$t('lang.en')
                },
                {
                    key: 'hr',
                    name: this.$t('lang.hr')
                }
            ]
        },
        activeLang () {
            const key = this.$store.state.lang || 'cz'
            return this.langs.find(item => item.key === key)
        },
        location () {
            return window.location
        }
    },
    methods: {
        onSetLang (lang) {
            this.$store.commit('setLang', lang.key)
            this.$i18n.locale = `${this.$store.state.lang}`
        }
    }
}
</script>
