<template>
    <div id="app" class="main-flex">
        <header class="main-header shadow-sm">
            <top-bar />
            <app-navbar>
                <b-nav-item-dropdown right>
                    <template #button-content>
                        <span class="iconify mr-2 text-primary" data-icon="mdi:account"></span>{{user.fullName}}
                    </template>
                    <b-dropdown-item :href="'javascript:void(0);'" @click.prevent="onLogout">
                        <span class="iconify mr-2 text-danger" data-icon="mdi:account-lock"></span>{{$t('navigation.logout')}}
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </app-navbar>
        </header>

        <div :class="{
            'main-content': true,
            'bg-gradient-cyrrus-gray-dark': $route.name !== 'Index',
            'bg-gradient-cyrrus-yellow': $route.name === 'Index'
        }">
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="out-in"
            >
                <router-view></router-view>
            </transition>
        </div>

        <app-footer/>
    </div>
</template>

<script>
import AppFooter from './Components/Layout/Footer.vue'
import TopBar from './Components/Layout/TopBar.vue'
import AppNavbar from './Components/Layout/AppNavbar.vue'

export default {
    name: 'App',
    components: {
        AppFooter,
        TopBar,
        AppNavbar
    },
    computed: {
        user () {
            return this.$user
        }
    },
    methods: {
        async onLogout () {
            try {
                await this.$api.auth.logout()
            } catch (error) {
                console.error(error)
            }
            this.$user.onLoggedOut()
            this.$notify.success(this.$t('navigation.loggedOutMessage'))
            this.$router.replace({ name: 'Index' })
        }
    }
}
</script>

<style lang="scss">
@import './scss/app.scss';

.form-sm .custom-select {
    height: 2.5rem;
    padding: .5rem;
    border-radius: 0;
}
</style>
