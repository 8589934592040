import { Auth } from './Auth'
import { Sessions } from './Sessions'
import { SignatureSessions } from './SignatureSessions'

export default [
    {
        property: 'auth',
        endpointClass: Auth
    },
    {
        property: 'enums'
    },
    {
        property: 'sessions',
        endpointClass: Sessions
    },
    {
        property: 'signatureSessions',
        urlPart: 'signature-sessions',
        endpointClass: SignatureSessions
    }
]
