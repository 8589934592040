import { axiosClient as axios } from './AxiosPlugin.js'
import Vue from 'vue'
import { ClientData } from '@cyrrus/clientdatastructure'

class User {
    constructor (
        onLoggedInCallback,
        onLoggedOutCallback
    ) {
        this.loggedInCallbacks = []
        this.loggedOutCallbacks = []

        this.onLogin(onLoggedInCallback)
        this.onLogout(onLoggedOutCallback)

        this.vm = new Vue({
            data: {
                isLoggedIn: false,
                clientData: new ClientData()
            },
            render () {
                return null
            }
        })
    }

    onLoggedIn (data) {
        this.vm.isLoggedIn = true
        this.vm.clientData = new ClientData(data)

        for (const fn of this.loggedInCallbacks) {
            fn(this)
        }
    }

    onLoggedOut () {
        this.vm.isLoggedIn = false
        this.vm.clientData = null

        for (const fn of this.loggedOutCallbacks) {
            fn(this)
        }
    }

    onLogin (callbackFn) {
        if (typeof callbackFn === 'function') {
            this.loggedInCallbacks.push(callbackFn)
        }
    }

    onLogout (callbackFn) {
        if (typeof callbackFn === 'function') {
            this.loggedOutCallbacks.push(callbackFn)
        }
    }

    async reload () {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API_BASE}/api/auth/me`)
            if (response.status === 200) {
                this.onLoggedIn(response.data)
            } else {
                this.onLoggedOut()
            }
        } catch (error) {
            // nothing
        }
        return this
    }

    get fullName () {
        return (this.vm.clientData) ? this.vm.clientData.fullName : null
    }

    get isLoggedIn () {
        return !!this.vm.isLoggedIn
    }

    get clientData () {
        return this.vm.clientData || null
    }
}

export const UserPlugin = {
    install (Vue) {
        const user = new User()
        Vue.prototype.$user = user
        Vue.prototype.$user.reload()
    }
}
