import { FluentApiEndpoint } from '@tvaliasek/fluent-axios-api-client'

export class Auth extends FluentApiEndpoint {
    login (username, password) {
        return this.doPostRequest(`/${this.url}/login`, { username, password })
    }

    logout () {
        return this.doGetRequest(`/${this.url}/logout`)
    }
}
