import { ElementsLibraryPlugin } from '@cyrrus/de-elements-library'
import basePack from '@cyrrus/base-pack'
import bootstrapPack from '@cyrrus/bootstrap-pack'
import formPack from '@cyrrus/form-pack'
import tablePack from '@cyrrus/table-pack'

const importedElements = [
    ...basePack,
    ...bootstrapPack,
    ...formPack,
    ...tablePack
]

for (const registerFn of importedElements) {
    registerFn(ElementsLibraryPlugin.library)
}

export const DocHubElementsPlugin = {
    install (Vue) {
        Vue.use(ElementsLibraryPlugin)
    }
}
