import axios from 'axios'
import { createClient } from '@tvaliasek/fluent-axios-api-client'
import endpoints from '../Services/Api'

function clientFactory (baseUrl) {
    const client = axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    })

    client.interceptors.response.use(
        function (response) {
            return response
        }, function (error) {
            if (error.response &&
                error.response.data &&
                error.response.status === 403 &&
                error.response.data.location
            ) {
                window.location = error.response.data.location
            }
            return Promise.reject(error)
        }
    )
    return client
}

export const ApiPlugin = (baseUrl, urlPrefix) => {
    const apiInstance = createClient(clientFactory(baseUrl), urlPrefix, endpoints)

    return {
        install (Vue) {
            Vue.prototype.$api = apiInstance
        },
        getApiClient () {
            return apiInstance
        }
    }
}
