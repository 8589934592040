import { axiosClient } from '../../Plugins/AxiosPlugin.js'

export class HeadRequestGuard {
    constructor (requestUrl, redirectRoute = null) {
        this.requestUrl = requestUrl
        this.redirectRoute = redirectRoute
    }

    beforeEnter (needRole = null) {
        const requestUrl = (needRole) ? `${this.requestUrl}?needRole=${needRole}` : this.requestUrl
        return (to, from, next) => {
            axiosClient.head(requestUrl)
                .then((response) => {
                    if (response.status === 200 || response.status === 204) {
                        next()
                    }
                }).catch(() => {
                    if ((typeof this.redirectRoute === 'object') && this.redirectRoute !== null) {
                        if (this.redirectRoute.url) {
                            window.location = this.redirectRoute.url
                            return
                        }
                        try {
                            next(this.redirectRoute)
                        } catch (e) {
                            // nothing to see here :)
                        }
                    } else {
                        next(false)
                    }
                })
        }
    }

    beforeEnterLogin () {
        const requestUrl = this.requestUrl
        return (to, from, next) => {
            axiosClient.head(requestUrl)
                .then((response) => {
                    if (response.status < 300) {
                        next({ name: 'Landing' })
                    }
                }).catch(() => {
                    next()
                })
        }
    }
}
