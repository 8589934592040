const DEFAULT_SETTINGS = {
    API_BASE: process.env.VUE_APP_API_BASE,
    ADMIN_APP_GUARD_URL: process.env.VUE_APP_ADMIN_APP_GUARD_URL,
    APP_GUARD_URL: process.env.VUE_APP_APP_GUARD_URL,
    ENABLE_LANG_SWITCHER: process.env.VUE_APP_ENABLE_LANG_SWITCHER === 'true' || process.env.VUE_APP_ENABLE_LANG_SWITCHER === true,
    ENABLE_API_DOC: process.env.VUE_APP_ENABLE_API_DOC === 'true' || process.env.VUE_APP_ENABLE_API_DOC === true
}

export default class Settings {
    constructor (
        settings = {}
    ) {
        this._settings = { ...DEFAULT_SETTINGS, ...settings }
    }

    get API_BASE () {
        return this._settings.API_BASE
    }

    get ADMIN_APP_GUARD_URL () {
        return (new URL(this._settings.ADMIN_APP_GUARD_URL, this.API_BASE)).toString()
    }

    get APP_GUARD_URL () {
        return (new URL(this._settings.APP_GUARD_URL, this.API_BASE)).toString()
    }

    get ENABLE_LANG_SWITCHER () {
        return this._settings.ENABLE_LANG_SWITCHER || false
    }

    get ENABLE_API_DOC () {
        return this._settings.ENABLE_LANG_SWITCHER || false
    }
}
