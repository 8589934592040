import { FluentApiEndpoint } from '@tvaliasek/fluent-axios-api-client'

export class SignatureSessions extends FluentApiEndpoint {
    async getSession () {
        return await this.read('current')
    }

    getIframeUrl (documentDataId) {
        return `${this.client.defaults.baseURL}/${this.url}/current/documents/${documentDataId}/pdf`
    }

    async isOtpVerified () {
        return await this.doHeadRequest(`/${this.url}/current`)
    }

    async sendOtp (locale) {
        return await this.doPostRequest(`/${this.url}/current/otp`, { locale })
    }

    async validateOtp (code) {
        return await this.doPatchRequest(`/${this.url}/current/otp`, { code })
    }

    async sendSignatureSms (locale) {
        return await this.doPostRequest(`/${this.url}/current/signature`, { locale })
    }

    async validateSignatureSms (code) {
        return await this.doPatchRequest(`/${this.url}/current/signature`, { code })
    }
}
