import {
    ButtonPlugin, FormPlugin, InputGroupPlugin, FormInputPlugin, FormRadioPlugin,
    FormGroupPlugin, CardPlugin, TabsPlugin, FormSelectPlugin, FormCheckboxPlugin,
    IconsPlugin, SpinnerPlugin, AlertPlugin, CalendarPlugin, FormDatepickerPlugin,
    NavbarPlugin, ToastPlugin, FormTimepickerPlugin, BadgePlugin, ProgressPlugin,
    ModalPlugin
} from 'bootstrap-vue'

const components = [
    ButtonPlugin, FormPlugin, InputGroupPlugin, FormInputPlugin, FormRadioPlugin,
    FormGroupPlugin, CardPlugin, TabsPlugin, FormSelectPlugin, FormCheckboxPlugin,
    IconsPlugin, SpinnerPlugin, AlertPlugin, CalendarPlugin, FormDatepickerPlugin,
    NavbarPlugin, ToastPlugin, FormTimepickerPlugin, BadgePlugin, ProgressPlugin,
    ModalPlugin
]

export const BootstrapComponentsPlugin = {
    install (Vue) {
        for (const component of components) {
            Vue.use(component)
        }
    }
}
